import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";

function NavBar() {
  const { rpdata } = useContext(GlobalDataContext);
  const Navigation = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "About",
      path: "/about",
    },
    {
      name: "Services",
      path: "/services",
    },
    {
      name: "Gallery",
      path: "/gallery",
    },
    {
      name: "Contact",
      path: "/contact",
    },
  ];

  return (
    <div className="absolute z-50 w-full">
      <header>
        <nav className="flex flex-wrap items-center justify-between w-full py-2 md:py-6 md:px-20 px-4 text-lg text-white">
          <div className="md:w-[50%] w-[50%]">
            <img src={rpdata?.dbPrincipal?.logo} className="md:w-[30%] w-[100%]" alt="Not Found"/>
          </div>
          <div className="text-center flex justify-center">
            <button
              id="menu-button"
              className="cursor-pointer md:hidden block w-[100%] burguer-menu"
              onClick={() => {
                document.querySelector("#menu").classList.toggle("hidden");
              }}
            >
              <GiHamburgerMenu />
            </button>
          </div>
          <div className="hidden w-full md:flex md:items-center md:w-[50%] justify-end md:bg-transparent bg-white rounded-sm" id="menu">
            <ul className="pt-4 text-base text-gray-700 md:flex md:justify-between md:pt-0 p-4">
              {Navigation?.map((item, index) => {
                return (
                  <li key={index}>
                    <Link
                      className="md:p-4 py-2 px-4 block md:text-white text-black"
                      to={item.path}
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
              <li>
                <Link
                  className="md:py-4 md:px-6 w-[200px] py-2 md:mt-0 mt-5 block btn-global text-center"
                  to="/contact"
                >
                  FREE ESTIMATE
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default NavBar;
