import React from "react";
import { Link } from "react-router-dom";

const ServicesBlock = ({
  ServiceName,
  TextService,
  bgimg,
  ClassesRow,
  DisplayNone,
  itemServices,
}) => {
  return (
    <div
      className="md:flex  md:flex-row content-text-bg md:p-0 px-2 my-[200px]"
      style={{
        flexDirection: `${ClassesRow}`,
      }}
    >
      <div className="md:w-[50%] md:self-center md:my-[-40px] md:ml-[-40px] md:p-10 p-4  shadow-lg bg-white z-10">
        <h3 className={DisplayNone}>{ServiceName}</h3>
        <div
          className={`md:w-[50%] w-full h-0.5 bg-black my-2 ${DisplayNone}`}
        ></div>
        <p>{TextService}</p>
        <ul>{itemServices}</ul>
        <Link
          className="md:py-4 md:px-6 py-2 block btn-global md:w-52 w-full text-center mt-8"
          to="/contact"
        >
          FREE ESTIMATE
        </Link>
      </div>
      <div className="md:w-[50%] w-full flex self-center">
        <div
          className="w-full md:h-[500px] h-[350px] bg-cover bg-center"
          style={{ backgroundImage: `url("${bgimg}")` }}
        ></div>
      </div>
    </div>
  );
};

export default ServicesBlock;
