import React from "react";

function VideoPromo({ bgimg }) {
  return (
    <div className="">
      <div
        className="call-to-action-area bg-overlay-2 rounded-b-[200px] after:rounded-b-[200px]"
        style={{ backgroundImage: `url("${bgimg}")` }}
      >
        <div className="w-full content-text md:flex md:text-start text-center justify-center item-center">
          <div className="text-star max-w-full w-full flex md:flex-row flex-col text-center justify-center item-center">
            <iframe
              className="rounded-3xl mb-[-100px] md:w-[800px] md:h-[500px] w-full h-[250px]"
              src="https://www.youtube.com/embed/RF-zuVAizxo"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPromo;