import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";

function BlockHomeTwo() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <section className="w-full flex justify-center md:py-32 py-4">
        <div className="max-w-6xl md:flex md:flex-row flex flex-col md:p-0 px-2 content-reverse">
          <div className="md:w-[50%]">
            <div
              className="w-full md:h-[800px] h-[350px] bg-cover bg-center"
              style={{ backgroundImage: `url("${rpdata?.gallery?.[6]}")` }}
            ></div>
          </div>
          <div className="md:w-[50%] py-4 md:px-8 px-3 self-center md:text-start text-center">
            <h3 className="separator-about">{rpdata?.dbSlogan?.[1].slogan}</h3>
            <p>{rpdata?.dbHome?.[1].text}</p>
            <Link
              className="md:py-4 md:px-6 py-2 block btn-global md:w-52 w-full text-center mt-8"
              to="/contact"
            >
              FREE ESTIMATE
            </Link>
            <div className="w-full">
              <span className="separator-about"></span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BlockHomeTwo;
