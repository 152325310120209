import React from "react";
import { Link } from "react-router-dom";

const CallToActionTwo = ({ SloganOne, SloganTwo, bgimg, phone }) => {
  return (
    <div>
      <div
        className="call-to-action-area bg-overlay"
        style={{ backgroundImage: `url("${bgimg}")` }}
      >
        <div className="w-3/5 mx-auto flex flex-col items-center">
        <h3 className="text-white relative z-10 text-center">{SloganOne}</h3>
        <Link
                  className="md:py-4 md:px-6 py-2 block btn-global md:w-52 w-full text-center mt-8 relative z-10"
                  to="/contact"
                >
                  FREE ESTIMATE
                </Link>
        </div>
      </div>
    </div>
  );
};

export default CallToActionTwo;
