import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const CallToAction = ({ SloganOne, SloganTwo, bgimg }) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div
        className="call-to-action-area bg-overlay-2"
        style={{ backgroundImage: `url("${bgimg}")` }}
      >
        <div className="md:w-8/12 p-4 mx-auto md:text-start text-center flex md:flex-row flex-col">
          <div className="md:w-[50%] w-full text-star">
           <img src={rpdata?.gallery?.[16]} alt="" className="md:mt-[-150px] md:w-[500px] md:h-[800px] w-[500px] h-[400px] relative z-10 object-cover"/>
          </div>
          <div className="md:w-[50%] w-full text-star p-4 flex flex-col">
            <div>
              <h4 className="text-white text-[35px] relative z-10 mt-6">Mision</h4>
              <p className="text-white relative z-10">{rpdata?.dbValues?.[0].description}</p>
            </div>
            <div>
              <h4 className="text-white text-[35px] relative z-10 mt-6">Vision</h4>
              <p className="text-white relative z-10">{rpdata?.dbValues?.[0].description}</p>
            </div>
            <div>
              <h4 className="text-white text-[35px] relative z-10 mt-6">Why Choose Us</h4>
              <p className="text-white relative z-10">{rpdata?.dbValues?.[0].description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
