import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import HeroSection from "../components/Home/HeroSection";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import BlockHomeTwo from "../components/Home/BlockHomeTwo";
import CallToActionTwo from "../components/global/CallToActionTwo";
import ServicesHome from "../components/Services/ContentServices";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";
import VideoPromo from "../components/Home/VideoPromo";
import CallToAction from "../components/global/CallToAction";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal/>
        <HeroSection />
        <BlockHomeOne/>
        <Directories/>
        <VideoPromo bgimg={`${rpdata?.gallery?.[9]}`} />
        <BlockHomeTwo/>

        <CallToAction
          SloganOne={rpdata?.dbSlogan?.[1].slogan}
          SloganTwo={rpdata?.dbSlogan?.[1].slogan}
          bgimg={`${rpdata?.gallery?.[6]}`}
          phone={rpdata?.dbPrincipal?.phones[0].phone}
        />
        <BlockAboutOne />
        <div className="flex flex-wrap justify-center items-center w-4/5 mx-auto">
          <div className="text-center md:w-[220px] w-full h-[100px] flex justify-center self-center items-center p-2">
            <a
              href="https://images.sherwin-williams.com/content_images/sw-pdf-sherwin-williams-colorc.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2FSW-1.jpg?alt=media&token=288b0d1c-3606-4e99-98a9-5f3539cd0081"
                alt="Not found"
                width={"250px"}
              />
            </a>
          </div>
          <div className="text-center md:w-[220px] w-full h-[100px] flex justify-center self-center items-center p-2">
            <a
              href="https://www.behr.com/consumer/colors/paint/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbehr-1.jpg?alt=media&token=5b20facf-8923-4bad-9c02-b2d6d9f5b44e"
                alt="Not found"
                width={"250px"}
              />
            </a>
          </div>
          <div className="text-center md:w-[220px] w-full h-[100px] flex justify-center self-center items-center p-2">
            <a
              href="https://www.benjaminmoore.com/en-us/color-overview/color-palettes"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/gallery-ae580.appspot.com/o/Directorios%2Fbjm-1.jpg?alt=media&token=30c85c07-dcc1-4601-81cf-40186bbc014b"
                alt="Not found"
                width={"250px"}
              />
            </a>
          </div>
        </div>
        <ServicesHome/>
        <CallToActionTwo
        SloganOne={rpdata?.dbSlogan?.[3].slogan}
        SloganTwo={rpdata?.dbSlogan?.[1].slogan}
        bgimg={`${rpdata?.gallery?.[6]}`}
        phone={rpdata?.dbPrincipal?.phones[0].phone}
        />
        <FormHome/>
        <Map/>
        </div>
    </BaseLayout>
  );
}

export default Home;
