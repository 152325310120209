import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.gallery?.[2]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.gallery?.[2]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.gallery?.[3]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.gallery?.[4]}")` }}
        ></figure>
        <div className="z-10 relative md:pt-[300px] pt-[200px] pb-[120px] flex flex-col w-8/12 mx-auto items-start justify-start">
          <h1 className="text-white md:text-start text-center">
            {rpdata?.dbSlogan?.[0].slogan}
          </h1>
          <p className="text-white md:text-start text-center relative z-10">{rpdata?.dbValues?.[0].description}</p>
          <Link
            className="md:py-4 md:px-6 py-2 block btn-global text-center w-52 md:text-start mt-8"
            to="/contact"
          >
            FREE ESTIMATE
          </Link>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
